import React, { ReactNode } from 'react';
import clsx from 'clsx';
import useEventCallback from '../../utils/useEventCallback';
import { Feature, invokesFeature, trackFeature } from '../../../../utils/features';
import styles from './index.module.css'; // eslint-disable-line

export const ButtonSizes = ['M', 'S'] as const;
export type ButtonSize = (typeof ButtonSizes)[number];

export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Text = 'text',
  RedText = 'redText',
}

export type ButtonProps = {
  size?: ButtonSize;
  variant?: ButtonVariant;
  className?: string;
  children: ReactNode;
  icon?: ReactNode;
  iconPosition?: 'left' | 'right';
  block?: boolean;
  featureId?: Feature;
  responsive?: boolean;
  testId?: string;
} & Omit<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  'ref'
>;

const RenderIcon = ({ icon }: Pick<ButtonProps, 'icon'>) => {
  return <div className={styles.iconContainer}>{icon}</div>;
};

// Disable eslint to preserve the type of Button after memoization, without
// having to explicitly cast "Button" to expected/resulting type.
// eslint-disable-next-line react/display-name
const Button = React.memo(
  React.forwardRef<HTMLButtonElement, ButtonProps>(function Button(
    {
      children,
      className,
      size = 'M',
      variant = ButtonVariant.Secondary,
      type = 'button',
      block,
      icon,
      iconPosition = 'left',
      onClick,
      featureId,
      ...rest
    }: ButtonProps,
    ref,
  ) {
    const handleClick = useEventCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      onClick?.(e);
      featureId && trackFeature(featureId);
    });
    return (
      <button
        ref={ref}
        className={clsx(
          styles.button,
          {
            [styles.block]: block,
            [styles.text]: variant === 'redText',
          },
          styles[size],
          styles[variant],
          'no-wrap',
          className,
        )}
        type={type}
        onClick={handleClick}
        {...invokesFeature(featureId)}
        {...rest}
      >
        <div className={styles.body}>
          {icon && iconPosition === 'left' && <RenderIcon icon={icon} />}
          {children}
          {icon && iconPosition === 'right' && <RenderIcon icon={icon} />}
        </div>
      </button>
    );
  }),
);

export default Button;

export type IconButtonProps = Pick<
  ButtonProps,
  'onClick' | 'featureId' | 'testId' | 'className' | 'block'
> & {
  icon: ReactNode;
  srOnly?: string;
  primary?: boolean;
} & Omit<
    React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    'ref'
  >;

/**
 * An IconButton is more specific, does not include variants, sizes nor types.
 */
const IconButtonBase = React.memo(
  React.forwardRef<HTMLButtonElement, IconButtonProps>(function IconButton(
    {
      className,
      type = 'button',
      block,
      icon,
      onClick,
      featureId,
      srOnly,
      primary,
      ...rest
    }: IconButtonProps,
    ref,
  ) {
    const handleClick = useEventCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      onClick?.(e);
      featureId && trackFeature(featureId);
    });

    return (
      <button
        ref={ref}
        className={clsx(
          styles.button,
          {
            [styles.block]: block,
            [styles.primary]: primary,
          },
          className,
        )}
        type={type}
        onClick={handleClick}
        {...invokesFeature(featureId)}
        {...rest}
      >
        <RenderIcon icon={icon} />
        {srOnly && <span className="sr-only">{srOnly}</span>}
      </button>
    );
  }),
);

export const IconButton: React.FC<IconButtonProps> = props => (
  <IconButtonBase {...props} className={clsx(styles.iconButton, props.className)} />
);

export const ToolbarIconButton: React.FC<IconButtonProps> = props => (
  <IconButtonBase {...props} className={clsx(styles.toolbarIconButton, props.className)} />
);
