import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useGetAccountCountForAdminConsoleQuery } from '../../../../generated/graphql';
import { ReactComponent as CrispLogo } from '../../../../assets/clogo.svg';
import { ReactComponent as AdvantageLogo } from '../../../../assets/advantage-logo.svg';
import { ReactComponent as InstacartLogo } from '../../../../assets/instacart.svg';
import HighImpactLogo from '../../../../assets/highImpact-logo.png';
import { useIsCompactNavRail } from '../../components/NavRail/NavRailContextProvider';
import { path } from '../routing/lib';
import { useThemeContext } from '../../components/Theme/ThemeProvider';
import { Theme } from '../../components/Theme';
import { useAccountDetails } from '../../../../pageTemplates/AccountTemplate/AccountDetails';
import { useAuthorizationContext } from '../../../security/AuthorizationContext';
import SubMenuPopover from '../../components/NavRail/SubMenuPopover';
import usePopoverSubMenu from '../../components/NavRail/usePopoverSubMenu';
import style from './LogoLink.module.css';

const LogoStyles: Partial<Record<Theme, object>> = {
  [Theme.Advantage]: {
    backgroundColor: 'white',
    borderRadius: '50%',
    width: '36px',
    height: '36px',
  },
  [Theme.HighImpact]: {
    width: '24px',
  },
};

const LogoComponents: Partial<Record<Theme, typeof CrispLogo>> = {
  [Theme.Advantage]: AdvantageLogo,
  [Theme.Instacart]: InstacartLogo,
};

const getLogoSvgComponent = (theme: Theme) => {
  return LogoComponents[theme] || CrispLogo;
};

const Logo: React.FC = () => {
  const { theme } = useThemeContext();
  if (theme === Theme.HighImpact) {
    return <img src={HighImpactLogo} alt="logo" style={LogoStyles[Theme.HighImpact]} />;
  }
  const LogoSVG = getLogoSvgComponent(theme);
  return <LogoSVG style={LogoStyles[theme]} />;
};

const AccountToolTip: React.FC<{ accountId: string }> = ({ accountId }) => {
  const account = useAccountDetails(accountId);

  return (
    <div className={style.accountTooltip}>
      <div>
        <strong>{account?.name} </strong>
      </div>
      <div>{accountId}</div>
    </div>
  );
};

const LogoLink: React.FC<{ accountId: string | undefined }> = ({ accountId }) => {
  const [showAccount, setShowAccount] = useState(false);
  const { isSignedIn, getHasGlobalAdminPermission, firebaseUser } = useAuthorizationContext();
  const isGlobalAdmin = getHasGlobalAdminPermission();
  const { data } = useGetAccountCountForAdminConsoleQuery({
    skip: !isSignedIn || !firebaseUser || isGlobalAdmin,
  });
  const { popoverProps, setReferenceElement, referenceElementProps } =
    usePopoverSubMenu<HTMLElement>({
      placement: 'middle',
      isInitiallyVisible: true,
      id: 'account-tooltip',
      ariaLabel: 'account-tooltip',
    });
  const showAccountHelper = isGlobalAdmin || (data?.accounts.totalCount ?? 0) > 1;
  return (
    <>
      <Link
        ref={setReferenceElement}
        to={path('Home')()}
        className={clsx(style.logoContainer, { [style.compact]: useIsCompactNavRail() })}
        onMouseEnter={() => setShowAccount(true)}
        onMouseLeave={() => setShowAccount(false)}
        {...referenceElementProps}
      >
        <Logo />
        {accountId && showAccount && showAccountHelper && (
          <SubMenuPopover {...popoverProps}>
            <AccountToolTip accountId={accountId} />
          </SubMenuPopover>
        )}
      </Link>
    </>
  );
};

export default LogoLink;
