import React, { useRef } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { FCC } from '../../../../utils/types';
import { ExternalLinkTarget } from '../ExternalLink';
import { keyDownTriggerOnClick } from '../../../ui/lib';
import style from './index.module.css';
import itemStyle from '../lib/ActionItem.module.css'; // eslint-disable-line

type CardProps = {
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  testId?: string;
  to?: string;
  href?: string;
  target?: ExternalLinkTarget;
  noElevation?: boolean;
};

const Card: FCC<CardProps> = ({
  children,
  className,
  href,
  to,
  disabled,
  target,
  testId,
  onClick,
  noElevation = false,
}) => {
  const cardRef = useRef(null);
  const card = (
    <div
      ref={cardRef}
      className={clsx(
        !onClick && !to && !href ? itemStyle.inactiveItem : itemStyle.item,
        {
          [itemStyle.disabled]: disabled,
          [style.fillHeight]: to || href,
          [itemStyle.noElevation]: noElevation,
        },
        className,
      )}
      onClick={onClick}
      onKeyDown={onClick ? keyDownTriggerOnClick(onClick, cardRef) : undefined}
      role={onClick ? 'button' : undefined}
      data-testid={testId}
    >
      {children}
    </div>
  );

  if (disabled) {
    return card;
  }

  return to ? (
    <Link to={to} className={style.link} target={target}>
      {card}
    </Link>
  ) : href ? (
    <a href={href} className={style.link} target={target}>
      {card}
    </a>
  ) : (
    card
  );
};

export default Card;
