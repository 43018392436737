import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { path } from '../../crisp/routing/lib';
import { PartnerIdParams } from '../lib';
import { useIsCompactNavRail } from '../../components/NavRail/NavRailContextProvider';
import DotLogoPng from '../../../../assets/dot-logo.png';
// eslint-disable-next-line css-modules/no-unused-class
import style from '../../crisp/navigation/LogoLink.module.css';

enum PartnerType {
  Dot = 'dot',
}

const usePartnerType = (partnerId: string) => {
  useEffect(() => {
    console.log('Get partner type for id', partnerId);
  }, [partnerId]);

  return PartnerType.Dot;
};

const LogoStyles: Partial<Record<PartnerType, object>> = {
  [PartnerType.Dot]: {
    width: '52px',
    height: '52px',
  },
};

const DotLogo: React.FC = () => {
  return <img src={DotLogoPng} alt="logo" style={LogoStyles[PartnerType.Dot]} />;
};

type LogoLinkProps = PartnerIdParams;

const LogoLink: React.FC<LogoLinkProps> = ({ partnerId }) => {
  const partnerType = usePartnerType(partnerId);

  return (
    <Link
      to={path('PartnerMiniSiteHome')({ partnerId })}
      className={clsx(style.logoContainer, { [style.compact]: useIsCompactNavRail() })}
    >
      {partnerType === PartnerType.Dot && <DotLogo />}
    </Link>
  );
};

export default LogoLink;
