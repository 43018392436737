import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Bowser from 'bowser';
import { isAfter, parseISO, sub } from 'date-fns';
import { AnyObject, Maybe } from '@tellurian/ts-utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useHistory } from 'react-router';
import { FieldValues, FormState } from 'react-hook-form';
import {
  ConfigurationCredentialVerification,
  ConfigurationCredentialVerificationStep,
  Connector,
  ConnectorApplication,
  ConnectorConfiguration,
  ConnectorConfigurationDetailsFragment,
  ConnectorConfigurationPropertyValue,
  ConnectorConfigurationPropertyValueInput,
  ConnectorDetailsFragment,
  ConnectorProperty,
  ConnectorPropertyDisplayType,
  ConnectorPropertyReadOnlyType,
  CredentialVerificationStep,
  CredentialVerificationStepProgress,
} from '../../generated/graphql';
import { getMessage, MessageId, PageMessage } from '../PageMessage/messages';
import { withQueryParams } from '../../utils/querystringUtils';
import { LocalStorage, LocalStorageKey } from '../../utils/localStorage';
import { KnownFlags } from '../lettuce/common/featureFlags/KnownFlags';
import { FeatureFlagContext } from '../lettuce/common/featureFlags/FeatureFlags';
import { path } from '../lettuce/crisp/routing/lib';
import { ConnectorTab } from '../lettuce/crisp/routing/Tabs';
import {
  PrimaryPropertyValueInitializer,
  PropertyValueInitializer,
} from './configurations/Edit/customPropertyValueInitializers';
import { VerificationStep } from './configurations/VerificationSteps';
import { ConnectorTabId } from './List';

const browser = Bowser.getParser(window.navigator.userAgent);
const engine = browser.getEngine();
const isWebKit = engine.name === 'WebKit';

export const tabNames: Record<ConnectorTab, string> = Object.freeze({
  [ConnectorTab.Outbound]: 'Data Destinations',
  [ConnectorTab.Inbound]: 'Data Sources',
});

export type ExtendedConnector = Omit<ConnectorDetailsFragment, 'properties'> & {
  properties: ExtendedConnectorProperty[];
};

export type ExtendedConnectorConfiguration = Omit<
  ConnectorConfigurationDetailsFragment,
  'connector' | 'verification'
> & {
  connector: ExtendedConnector;
  credentialVerification: ConfigurationCredentialVerificationFragment | null | undefined;
};

export type ExtendedConnectorProperty = ConnectorProperty & {
  value?: string;
  autoComplete?: string;
  spellCheck?: boolean;
};

export type ConfigurationCredentialVerificationFragment = Omit<
  ConfigurationCredentialVerification,
  'application' | 'enabled' | 'failureCounter'
>;

type ConnectorVerificationStep = Omit<CredentialVerificationStep, 'progress'>;

export const allTabs = [ConnectorTab.Inbound, ConnectorTab.Outbound];

// For some fields, the flags should be specified on a per connector basis, as field names are not unique.
// E.g. Field named "foo" may not require autoComplete "off" for ConnectorX but may do so for ConnectorY
// Presently the following set is interpreted as:
// "all fields with these names should have auto-complete off, regardless of the connector they pertain to"
const AutoCompleteOffFieldNames = new Set(['description', 'username', 'password', 'path']);

const getAutoComplete = (fieldName: string): string | undefined => {
  if (AutoCompleteOffFieldNames.has(fieldName)) {
    return 'off';
  }

  return undefined;
};

const getSpellCheck = (fieldName: string): boolean | undefined =>
  fieldName === 'username' ? false : undefined;

const DescriptionProperty = Object.freeze({
  label: 'Name',
  defaultValue: null,
  description: 'A short description of the connector',
  optional: false,
  name: 'description',
  displayType: ConnectorPropertyDisplayType.TextInput,
  readOnlyType: ConnectorPropertyReadOnlyType.Never,
  secret: false,
  canEdit: true,
});

export const prependDescription = (properties: ExtendedConnectorProperty[]) => [
  DescriptionProperty,
  ...properties,
];

export const getConnectorFields = (
  properties: ExtendedConnectorProperty[],
  propertyValues: ConnectorConfigurationPropertyValue[],
): ExtendedConnectorProperty[] => {
  const values = propertyValues.reduce((dict, prop) => {
    dict[prop.name] = prop.value;
    return dict;
  }, {});
  return properties.map(f => ({
    ...f,
    autoComplete: getAutoComplete(f.name),
    spellCheck: getSpellCheck(f.name),
    value: values[f.name],
  }));
};

export const toPropertyValues = (
  fieldValues: Record<string, string>,
  filterPredicate?: ([name, value]: [string, string]) => boolean,
) => {
  const entries = Object.entries(fieldValues).filter(([name]) => name !== DescriptionProperty.name);
  return (filterPredicate ? entries.filter(filterPredicate) : entries).map(([name, value]) => ({
    name,
    value: value || '',
  }));
};

export const toPropertyValuesFilterOptional = (
  fieldValues: Record<string, string>,
  connector: Pick<Connector, 'properties'>,
): ConnectorConfigurationPropertyValueInput[] => {
  const optionalPropertyNames = new Set(
    connector.properties.filter(p => p.optional).map(p => p.name),
  );

  return toPropertyValues(
    fieldValues,
    ([name, value]) => !(optionalPropertyNames.has(name) && !value),
  );
};

/**
 * Returns an object representing merged connector configuration values with form field values. The purpose
 * of this function is to circumvent the fact that not all field values might be initialized when a clone is triggered.
 * @param connectorConfiguration
 * @param fieldValues
 */
export const getPropertyValuesToClone = (
  connectorConfiguration: Pick<ConnectorConfiguration, 'propertyValues'>,
  fieldValues: Record<string, string>,
): Record<string, string> =>
  Object.assign(
    connectorConfiguration.propertyValues.reduce<Record<string, string>>((res, { name, value }) => {
      res[name] = fieldValues[name] || value;
      return res;
    }, {}),
    fieldValues,
  );

export const excludeReadOnlyPropertyValues = (
  fieldValues: Record<string, string>,
  connector: Pick<Connector, 'properties'>,
  excludeReadOnlyType = ConnectorPropertyReadOnlyType.Always,
): Record<string, string> => {
  const filteredPropertyNames = new Set(
    connector.properties
      .filter(p => p.readOnlyType !== excludeReadOnlyType && p.canEdit)
      .map(p => p.name),
  );

  return Object.fromEntries(
    Object.entries(fieldValues).filter(([k]) => filteredPropertyNames.has(k)),
  );
};

const MissingConnectorProps = {
  setupGuideUrl: undefined,
};

export const extendConnector = (connector: ConnectorDetailsFragment): ExtendedConnector => {
  return {
    ...connector,
    ...MissingConnectorProps,
    properties: connector.properties.map(prop => ({
      ...prop,
    })),
  };
};

export const useGoToConnectors = (accountId: string, tab?: ConnectorTab) => {
  const history = useHistory();

  return (copyUrlOfConfigId?: string) => {
    // Exclude Webkit - Safari does not allow copying to clipboard unless it originates from
    // a user action (click, key)
    const queryParams = isWebKit ? {} : { copy: copyUrlOfConfigId };
    history.push(withQueryParams(queryParams)(path('Connectors')({ accountId, tab })));
  };
};

export const createSubmitLabel = ({
  isInProgress,
  isSaving,
  isTestable,
  isCloneRequired = false,
  isUpdated = true,
  isEnabled = true,
  isCloneSecondStep = false,
}: {
  isInProgress: boolean;
  isSaving: boolean;
  isTestable: boolean;
  isUpdated?: boolean;
  isEnabled?: boolean;
  isCloneRequired?: boolean;
  isCloneSecondStep?: boolean;
}) => {
  if (isSaving) {
    return isCloneRequired ? 'Cloning...' : 'Saving...';
  } else if (isInProgress) {
    return 'Testing...';
  } else if (isCloneSecondStep) {
    return 'Save clone';
  } else if (isCloneRequired) {
    return 'Clone';
  } else if (isTestable && isUpdated && isEnabled) {
    return 'Save & Test';
  }

  return 'Save';
};

export const getDefaultPropertyValues = (
  properties: ExtendedConnectorProperty[],
  propertyValues: ConnectorConfigurationPropertyValue[],
  initializers: PropertyValueInitializer[] = [],
) =>
  initializers.reduce(
    (result, fn) => fn(result, properties, propertyValues),
    PrimaryPropertyValueInitializer({}, properties, propertyValues),
  );

export const convertConfigurationStep = (
  step: ConfigurationCredentialVerificationStep,
): VerificationStep => ({
  name: step.name,
  label: step.label,
  number: step.number,
  progress: step.progress,
});

export const convertConnectorStep = (step: ConnectorVerificationStep): VerificationStep => ({
  name: step.name,
  label: step.label,
  number: step.number,
  progress: CredentialVerificationStepProgress.NotStarted,
});

export type Verification = {
  verificationSteps: VerificationStep[];
  isTesting: boolean;
  isTestable: boolean;
  isVerificationActive: boolean;
  isVerificationExpected: boolean;
  nextPollDelay: number;
  onSave: (isUpdated: boolean, isEnabled: boolean) => void;
  onRefetch: () => void;
};

const fiveSeconds = 5 * 1000;
const twentySeconds = 20 * 1000;
const oneMinute = 60 * 1000;

export type ConfigurationForVerification = {
  credentialVerification?: {
    steps: ConfigurationCredentialVerificationStep[];
    nextUpdateExpectedAt: string;
    running: boolean;
  } | null;
};

// Do not use Number.MAX_SAFE_INTEGER as a poll delay with useInterval. This equates to 0 causing
// rapid successive executions of the fn passed as param.
export const MAX_POLL_DELAY = Math.floor(Number.MAX_SAFE_INTEGER / 10);

const checkIsVerificationExpected = (savedTime?: number) => {
  if (!savedTime) {
    return false;
  }

  const now = Date.now();

  return +now < +savedTime + twentySeconds;
};

export const calculateNextPollDelay = (
  running?: boolean,
  nextUpdateExpectedAt?: string,
  isVerificationExpected?: boolean,
) => {
  const now = Date.now();
  if (running || isVerificationExpected) {
    return fiveSeconds;
  } else if (typeof nextUpdateExpectedAt === 'string') {
    const nextUpdateTime = +parseISO(nextUpdateExpectedAt);
    if (nextUpdateTime >= now + fiveSeconds) return nextUpdateTime - now;
    if (nextUpdateTime < now - oneMinute) return MAX_POLL_DELAY;
  }
  return fiveSeconds;
};

export const useVerification = (
  configuration: Maybe<ConfigurationForVerification>,
  connectorVerificationSteps: Maybe<ConnectorVerificationStep[]>,
): Verification => {
  const [savedTime, setSavedTime] = useState<Maybe<number>>();
  const [isVerificationExpected, setVerificationExpected] = useState(false);

  const onRefetch = useCallback(() => {
    setVerificationExpected(checkIsVerificationExpected(savedTime));
  }, [savedTime]);

  const onSave = useCallback((isUpdated: boolean, isEnabled: boolean) => {
    if (!isUpdated || !isEnabled) {
      return;
    }
    setVerificationExpected(true);
    setSavedTime(Date.now());
  }, []);

  useEffect(() => {
    if (configuration?.credentialVerification?.running && isVerificationExpected) {
      setSavedTime(undefined);
      setVerificationExpected(false);
    }
  }, [configuration?.credentialVerification?.running, isVerificationExpected]);

  return useMemo(() => {
    if (!configuration?.credentialVerification || !connectorVerificationSteps) {
      return {
        verificationSteps: [],
        isTesting: false,
        isTestable: false,
        isVerificationActive: false,
        isVerificationExpected: false,
        nextPollDelay: MAX_POLL_DELAY,
        onSave: () => {},
        onRefetch: () => {},
      };
    }

    const { steps, running, nextUpdateExpectedAt } = configuration.credentialVerification;
    const actualConfigSteps = steps.map(convertConfigurationStep);
    const actualConnectorSteps =
      connectorVerificationSteps?.length && connectorVerificationSteps?.map(convertConnectorStep);
    const isTestable = connectorVerificationSteps.length > 0;

    const nextPollDelay = isTestable
      ? calculateNextPollDelay(running, nextUpdateExpectedAt, isVerificationExpected)
      : MAX_POLL_DELAY;

    return {
      isTestable,
      verificationSteps: actualConfigSteps || actualConnectorSteps || [],
      isTesting: running,
      isVerificationActive: steps.length !== 0,
      isVerificationExpected,
      nextPollDelay,
      onSave,
      onRefetch,
    };
  }, [configuration, connectorVerificationSteps, onSave, onRefetch, isVerificationExpected]);
};

const tabApplications: Record<ConnectorTab, ConnectorApplication[]> = Object.freeze({
  [ConnectorTab.Inbound]: [
    ConnectorApplication.Albertsons,
    ConnectorApplication.AmazonSellerCentral,
    ConnectorApplication.AssociatedWholesaleGrocers,
    ConnectorApplication.BjsWholesale,
    ConnectorApplication.CAndS,
    ConnectorApplication.HyVee,
    ConnectorApplication.Kehe,
    ConnectorApplication.Kroger,
    ConnectorApplication.Meijer,
    ConnectorApplication.SoutheasternGrocers,
    ConnectorApplication.Target,
    ConnectorApplication.Unfi,
    ConnectorApplication.Walmart,
    ConnectorApplication.Wakefern,
    ConnectorApplication.Wegmans,
    ConnectorApplication.WholeFoods,
    ConnectorApplication.Dot,
    ConnectorApplication.Rema,
    ConnectorApplication.AmazonVendorCentral,
    ConnectorApplication.CvsPharmacy,
    ConnectorApplication.SuperValu,
    ConnectorApplication.Shopify,
    ConnectorApplication.ShopifyPrivate,
    ConnectorApplication.Bunzl,
    ConnectorApplication.Snyders,
    ConnectorApplication.DollarGeneral,
    ConnectorApplication.GoogleSheetsMasterData,
    ConnectorApplication.LinkedAccountConnection,
    ConnectorApplication.GoogleCm360,
    ConnectorApplication.Gnc,
    ConnectorApplication.Mclane,
    ConnectorApplication.HomeDepot,
    ConnectorApplication.Sobeys,
    ConnectorApplication.Harvest,
    ConnectorApplication.SevenEleven,
    ConnectorApplication.BigLots,
    ConnectorApplication.Walgreens,
    ConnectorApplication.Aldi,
    ConnectorApplication.GiantEagle,
    ConnectorApplication.PigglyWiggly,
    ConnectorApplication.TotalWineAndMore,
    ConnectorApplication.GroceryOutlet,
    ConnectorApplication.Ampm,
    ConnectorApplication.TraderJoes,
    ConnectorApplication.SpartanNash,
    ConnectorApplication.WincoFoods,
    ConnectorApplication.CustomConnector,
    ConnectorApplication.Nielsen,
    ConnectorApplication.CrispOne,
    ConnectorApplication.Faire,
    ConnectorApplication.HarrisTeeter,
    ConnectorApplication.TonysFineFoodsEmail,
    ConnectorApplication.As2Kroger,
    ConnectorApplication.DemoData,
    ConnectorApplication.CentralGardenAndPet,
    ConnectorApplication.Rei,
    ConnectorApplication.Dpi,
    ConnectorApplication.SamsClub,
    ConnectorApplication.AmazonFresh,
    ConnectorApplication.KeheDeductions,
    ConnectorApplication.UnfiDeductions,
    ConnectorApplication.Tesco,
    ConnectorApplication.DotFoodsShop,
    ConnectorApplication.UnfiInsights,
    ConnectorApplication.Skai,
    ConnectorApplication.WalmartLuminate,
    ConnectorApplication.WalmartLuminateCategoryAdvisor,
    ConnectorApplication.Ulta,
    ConnectorApplication.BestBuy,
    ConnectorApplication.HomeDepotCanada,
    ConnectorApplication.WalmartChargebacks,
    ConnectorApplication.KrogerStratumDirect,
    ConnectorApplication.WalmartLuminateBasic,
    ConnectorApplication.WalmartLuminateAtlasLink,
    ConnectorApplication.HarrisTeeterDirect,
    ConnectorApplication.WalmartNielsenAlcoholBeverages,
    ConnectorApplication.PartnerIngestion,
    ConnectorApplication.TargetGreenfield,
    ConnectorApplication.HarmonizedRetailer,
  ],
  [ConnectorTab.Outbound]: [
    ConnectorApplication.Excel,
    ConnectorApplication.ExcelAddin,
    ConnectorApplication.PowerBi,
    ConnectorApplication.Tableau,
    ConnectorApplication.AzureBlobStorage,
    ConnectorApplication.Snowflake,
    ConnectorApplication.AmazonRedshift,
    ConnectorApplication.GoogleBigquery,
    ConnectorApplication.AzureSynapseDataWarehouse,
    ConnectorApplication.GoogleCloudStorage,
    ConnectorApplication.AwsS3,
    ConnectorApplication.GoogleDv360,
    ConnectorApplication.FacebookAdsManager,
    ConnectorApplication.ThetradedeskSolimar,
    ConnectorApplication.GoogleDataStudio,
    ConnectorApplication.OdataApi,
    ConnectorApplication.AzureDataLakeStorageGen2,
    ConnectorApplication.ThetradedeskSegmentActivation,
    ConnectorApplication.Databricks,
    ConnectorApplication.PowerBiPremium,
  ],
});

export const applicationsForTab = (tab: ConnectorTab): ConnectorApplication[] =>
  tabApplications[tab];

export const tabForApplication = (application: ConnectorApplication): Maybe<ConnectorTab> =>
  allTabs.find(tab => applicationsForTab(tab).includes(application));

export const matchesTab = (tab: ConnectorTab, application: ConnectorApplication): boolean =>
  tabForApplication(application) === tab;

export const matchesTabId = (tabId: ConnectorTabId, application: ConnectorApplication): boolean =>
  matchesTab(tabId === 'sources' ? ConnectorTab.Inbound : ConnectorTab.Outbound, application);

const connectorMatchesTab =
  (tab: ConnectorTab) =>
  ({ application }: Pick<Connector, 'application'>): boolean =>
    matchesTab(tab, application);

export const isInboundConnector = connectorMatchesTab(ConnectorTab.Inbound);
export const isOutboundConnector = connectorMatchesTab(ConnectorTab.Outbound);

const OutboundODataConnectors: Readonly<Set<ConnectorApplication>> = new Set([
  ConnectorApplication.Excel,
  ConnectorApplication.ExcelAddin,
  ConnectorApplication.PowerBi,
  ConnectorApplication.Tableau,
  ConnectorApplication.OdataApi,
]);

export const isOutboundODataConnector = ({
  application,
}: Pick<Connector, 'application'>): boolean => OutboundODataConnectors.has(application);

const OutboundExportConnectors: Readonly<Set<ConnectorApplication>> = new Set([
  ConnectorApplication.GoogleBigquery,
  ConnectorApplication.AzureBlobStorage,
  ConnectorApplication.AzureDataLakeStorageGen2,
  ConnectorApplication.AmazonRedshift,
  ConnectorApplication.AzureSynapseDataWarehouse,
  ConnectorApplication.GoogleDataStudio,
  ConnectorApplication.AwsS3,
  ConnectorApplication.Snowflake,
  ConnectorApplication.GoogleCloudStorage,
  ConnectorApplication.GoogleDv360,
  ConnectorApplication.PowerBiPremium,
]);

export const isOutboundExportConnector = ({
  application,
}: Pick<Connector, 'application'>): boolean => OutboundExportConnectors.has(application);

type VerificationMessageProps = {
  step: string;
  onRetry: (() => void) | undefined;
  retryDisabled: boolean;
};

const getMessageIdForFailedStepName = (failedStepName: string): MessageId => {
  if (failedStepName === 'VERIFY_WRITE_ACCESS') {
    return MessageId.TokenHasInsufficientPrivileges;
  } else if (failedStepName === 'VERIFY_TOKEN_VALID') {
    return MessageId.AccessTokenTokenExpired;
  } else if (failedStepName.startsWith('CONNECT_TO')) {
    return MessageId.ConnectionError;
  }

  return MessageId.ConnectorFailedVerificationStep;
};

// Do not display notifications for source connectors we do not require a user password.
export const isNotificationPreventedForConnectorConfiguration = (
  connector: Pick<Connector, 'application'> & { properties: Pick<ConnectorProperty, 'name'>[] },
) => isInboundConnector(connector) && !connector.properties.find(pv => pv.name === 'username');

export const getMessageForVerificationSteps = (
  steps: VerificationStep[],
  connector: Pick<Connector, 'application'> & { properties: Pick<ConnectorProperty, 'name'>[] },
  configurationId: string,
  onRetry: (() => void) | undefined,
  loading: boolean,
  isGlobalAdmin: boolean,
): Maybe<PageMessage & VerificationMessageProps> => {
  if (
    !steps?.length ||
    (isNotificationPreventedForConnectorConfiguration(connector) && !isGlobalAdmin)
  ) {
    return undefined;
  }

  const now = Date.now();
  const currentDebounceValues = LocalStorage.getItemOrDefault(
    LocalStorageKey.CredentialVerificationRetryDebounce,
    {},
  );
  const lastRetry = new Date(parseInt(currentDebounceValues[configurationId]) || '0');

  const onRetryWithDebounce = onRetry
    ? () => {
        currentDebounceValues[configurationId] = now.toString();
        LocalStorage.setItem(
          LocalStorageKey.CredentialVerificationRetryDebounce,
          currentDebounceValues,
        );
        onRetry();
      }
    : undefined;

  const failedStep = steps.find(
    step => step.progress === CredentialVerificationStepProgress.Failure,
  );
  if (failedStep) {
    return {
      ...getMessage(getMessageIdForFailedStepName(failedStep.name)),
      step: failedStep.label,
      onRetry: onRetryWithDebounce,
      retryDisabled: loading || (!isGlobalAdmin && isAfter(lastRetry, sub(now, { minutes: 20 }))),
    };
  }

  return undefined;
};

export const addConnectorDescription =
  'Integrate data from Data Sources and into Data Destinations';

export enum ConnectorConfigurationFormMode {
  Create,
  Update,
}

export const isReadOnly = (
  mode: ConnectorConfigurationFormMode,
  readOnlyType: ConnectorPropertyReadOnlyType,
  canEdit: boolean,
): boolean => {
  if (!canEdit) {
    return true;
  }
  switch (readOnlyType) {
    case ConnectorPropertyReadOnlyType.Never:
      return false;
    case ConnectorPropertyReadOnlyType.Create:
      return mode === ConnectorConfigurationFormMode.Create;
    case ConnectorPropertyReadOnlyType.Update:
      return mode === ConnectorConfigurationFormMode.Update;
    case ConnectorPropertyReadOnlyType.Always:
      return true;
  }
};

export const getEditableFieldsOnCreate = (properties: ExtendedConnectorProperty[]) =>
  getConnectorFields(prependDescription(properties), []).filter(
    f =>
      !isReadOnly(
        ConnectorConfigurationFormMode.Create,
        f.readOnlyType,
        true /* don't filter out uneditable fields that need to be added by a global admin */,
      ),
  );

export const CredentialPropertyDisplayTypes = new Set([
  ConnectorPropertyDisplayType.AzureContainerUrl,
  ConnectorPropertyDisplayType.AzureOauthCredential,
  ConnectorPropertyDisplayType.AmazonOauthToken,
  ConnectorPropertyDisplayType.AzureSasToken,
  ConnectorPropertyDisplayType.GoogleOauthToken,
  ConnectorPropertyDisplayType.ShopifyOauthToken,
  ConnectorPropertyDisplayType.Password,
]);
export const connectorRequiresCredentials = (connector: Pick<Connector, 'properties'>): boolean =>
  connector.properties.some(p => CredentialPropertyDisplayTypes.has(p.displayType));

// Exceptional way of excluding the Azure container path from the set of property values. This
// should be redefined on source connector version change.
const PropertyNamesWhichShouldBeResetInClone = new Set(['path']);
export const shouldClearConnectorPropertyValueInClone = (
  property: Pick<ConnectorProperty, 'displayType' | 'name' | 'canEdit'>,
) =>
  !property.canEdit ||
  CredentialPropertyDisplayTypes.has(property.displayType) ||
  PropertyNamesWhichShouldBeResetInClone.has(property.name);

// Should be moved to backend here: https://gocrisp.atlassian.net/browse/IJ-1481
export const connectorHasPropertiesWithRestrictedAccess = (
  connector: Pick<Connector, 'properties'>,
): boolean => connector.properties.some(p => !p.canEdit);

// This is similar to what you'd find in the `isDirty` flag of `formState`,
// but the original seems to always be true in our usage and dirtyFields are occasionally
// reset to all being true (erroneously).
export const isFormDirty = (formValues: AnyObject, defaultFormValues: AnyObject) =>
  Object.keys(formValues).some(k => defaultFormValues[k] !== formValues[k]);

export const addConnectorSupportSubject = encodeURIComponent(
  'Adding a New Connector to my Crisp Account',
);

export const ConnectorFlagsMap: Readonly<Partial<Record<ConnectorApplication, KnownFlags>>> = {
  [ConnectorApplication.UnfiDeductions]: KnownFlags.Deductions,
  [ConnectorApplication.KeheDeductions]: KnownFlags.Deductions,
};

export const useIsFlaggedConnectorAvailable = () => {
  const { featureFlagsReady } = useContext(FeatureFlagContext);
  const featureFlags = useFlags();
  return useMemo(() => {
    return (connector: Pick<Connector, 'application'>) => {
      const flag = ConnectorFlagsMap[connector.application];
      return !flag || (featureFlagsReady && featureFlags[flag]);
    };
  }, [featureFlags, featureFlagsReady]);
};

/**
 * This is similar to what you'd find in the `isDirty` flag of `formState`,
 * but the original seems to always be true in our usage and does not account
 * for whether or not we're already submitting the form.
 */
export const isDirty = <TFieldValues extends FieldValues = FieldValues>({
  dirtyFields,
  isSubmitting,
}: Pick<FormState<TFieldValues>, 'dirtyFields' | 'isSubmitting'>): boolean =>
  !!Object.keys(dirtyFields).length && !isSubmitting;
